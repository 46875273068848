import React, { useState } from "react";
import { createContainer } from "unstated-next";
import { useFetchCarportSizes } from "./carport/hooks/useFetchCarportSizes";
import { useFetchCarportData } from "./carport/hooks/useFetchCarportData";
import { useFetchCarportImages } from "./carport/hooks/useFetchCarportImages";
import { useFetchCarportPrice } from "./carport/hooks/useFetchCarportPrice";

const colorMap = {
  'white': 'Weiß (RAL 9016)',
  'weiss': 'Weiß (RAL 9016)',
  'anthrazit': 'Anthrazit (RAL 7016)',
  'ral': 'RAL ...',
};

const coverMap = {
  'pc_klar' : 'Polycarbonat 16 mm klar',
  'pc_bronce' : 'Polycarbonat 16 mm bronce',
  'pc_opal' : 'Polycarbonat 16 mm opal',
  'pc_weiss_opal' : 'Polycarbonat klar weiss opal gestreift',
  'acryl_klar' : 'Acryl 16 mm klar',
  'acryl_bronce' : 'Acryl 16 mm bronce',
  'acryl_klima' : 'Acryl 16 mm Klima blue',
  'glas' : 'Echtglas 10 mm VSG',
};

export const useStore = () => {
  //
  // Sizes
  //
  const { sizes, isSizesLoading, isSizesError } = useFetchCarportSizes();
  const [{ data, isDataLoading, isDataError,
    color, ral, cover, wall, serviceStatic, serviceMontage },
    setDataSize, setDataColor, setDataRalColor, setDataCover, setDataWall,
    setServiceStatic, setServiceMontage,] = useFetchCarportData();
  const [stdSize, setStdSize] = useState('3094x5620');

  const handleStdSizeChange = (event) => {
    const value = event.target.value;
    setDataSize(value);
    setStdSize(value);
    setImagesSize(value);
    setPriceSize(value);
  };

  const setStdSizeChange = (value) => {
    setDataSize(value);
    setStdSize(value);
    setImagesSize(value);
    setPriceSize(value);
  };

  const setWidthValue = (event) => {
    setSizes(sizes);
    setWidth(event.target.value);
  };

  const setLengthValue = (event) => {
    setSizes(sizes);
    setLength(event.target.value);
  };

  //
  // Images
  //
  const [{ images, isImagesLoading, isImagesError },
    setImagesSize, setImagesColor, setImagesCover] = useFetchCarportImages();

  //
  // Color
  //
  const handleColorChange = (event) => {
    const c = event.target.value;
    setDataColor(c);
    setImagesColor(c);
    setPriceColor(c);
  };

  const handleColorValueChange = (c) => {
    setDataColor(c);
    setImagesColor(c);
    setPriceColor(c);
  };

  //
  // Cover
  //
  const handleCoverChange = (event) => {
    setDataCover(event.target.value);
    setImagesCover(event.target.value);
    setPriceCover(event.target.value);
  };

  const handleCoverValueChange = (value) => {
    setDataCover(value);
    setImagesCover(value);
    setPriceCover(value);
  };

  //
  // Wall
  //
  const handleWallChange = (event) => {
    setDataWall(event.target.value);
  };

  const handleWallValueChange = (value) => {
    setDataWall(value);
  };

  //
  // Service
  //
  const toggleServiceStatic = () => {
    setServiceStatic(prev => !prev);
  };
  const handleChangeServiceMontage = (event) => {
    setServiceMontage(event.target.value);
  };

  //
  // Price
  //
  const [{ price, isPriceLoading, isPriceError,
           size, width, setWidth, length, setLength, isConfigurable },
    setPriceSize, setPriceColor, setPriceCover,
    setSizes, setIsConfigurable] = useFetchCarportPrice(setStdSizeChange);

  const toggleIsConfigurable = () => {
    setIsConfigurable(prev => !prev);
  };

  return {
    sizes,
    stdSize, handleStdSizeChange,
    width, setWidthValue, length, setLengthValue,
    color, colorMap, handleColorChange, handleColorValueChange,
    cover, coverMap, handleCoverChange, handleCoverValueChange,
    wall, handleWallChange, handleWallValueChange,
    serviceStatic, toggleServiceStatic,
    serviceMontage, handleChangeServiceMontage,
    data,
    images, isImagesLoading, isImagesError,
    price, isPriceLoading, isPriceError,
    isConfigurable, toggleIsConfigurable,
    isSizesLoading,
    isSizesError,
  }
};

export const CarportStoreContainer = createContainer(useStore);
