import React from "react";
import Typography from "@material-ui/core/Typography";
import { Card, CardActionArea, CardContent, Grid } from "@material-ui/core";
import { useTranslate } from 'react-admin';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import { StoreContainer } from "../../terraceStore";
import { IColor } from "../../types";

const colorWhite =  require('../../assets/images/terrace_color_white.png?placeholder=true&sizes[]=320');
const colorAnthrazit =  require('../../assets/images/terrace_color_anthrazit.png?placeholder=true&sizes[]=320');
const colorRal =  require('../../assets/images/terrace_color_ral.png?placeholder=true&sizes[]=320');

const imagesMap: Record<string, any> = {
  'weiss': colorWhite,
  'white': colorWhite,
  'anthrazit': colorAnthrazit,
  'ral': colorRal,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      backgroundColor: '#f9f9f9'
    },
    radio: {
      '&&': {
        position: 'absolute',
        opacity: 0,
        width: 0,
        height: 0,
      },
      '&& + img': {
        cursor: 'pointer',
      },
      '&&:checked + img': {
        outline: '2px solid #37474f',
      },
      '&& + img + div': {
        cursor: 'pointer',
      },
      '&&:checked + img + div': {
        backgroundColor: '#37474f',
        color: '#fff',
      },
    },
    media: {
      height: 100,
    },
  }),
);

const TeraceColorItems = () => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const isDataAvailable = (store.data && store.data.colors && store.data.colors.length > 0);
  const translate = useTranslate();

  return (
    isDataAvailable &&
    store.data.colors.map((c: IColor) => (
      <Grid item sm={4} xs={6} key={c.value}>
        <Card>
          <CardActionArea onClick={() => store.handleColorValueChange(c.value)}>
            <label>
              <input type="radio"
                     className={classes.radio}
                     name="color"
                     key={c.value}
                     value={c.value}
                     checked={(store.color as string) === c.value} />
              <CardMedia
                component="img"
                alt={translate(`colors.${c.value}`)}
                className={classes.media}
                image={imagesMap[c.value].src}
                srcSet={imagesMap[c.value].srcSet}
                title={translate(`colors.${c.value}`)}
              />
              <CardContent>
                {translate(`colors.${c.value}`)}
              </CardContent>
            </label>
          </CardActionArea>
        </Card>
      </Grid>
    ))
  );
};

const TerraceColor = () => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const isDataAvailable = (store.data && store.data.colors && store.data.colors.length > 0);
  const translate = useTranslate();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="inherit" component="h2">
          {translate('terrace.color.title')}
        </Typography>
      </Grid>
      {
        isDataAvailable ? (
          <TeraceColorItems />
        ) : (<div/>)
      }
    </Grid>
  )
};

export default TerraceColor;
