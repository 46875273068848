import { TranslationMessages } from 'ra-core';
import germanMessages from 'ra-language-german';

const customGermanMessages: TranslationMessages = {
    ...germanMessages,
    pos: {
        search: 'Suche',
        configuration: 'Konfiguration',
        language: 'Sprache',
        reset: 'Zurücksetzen',
        calculate: 'Berechnen',
        theme: {
            name: 'Thema',
            light: 'Hell',
            dark: 'Dunkel',
        },
        dashboard: {
            welcome: {
                title: 'Konfigurator APP',
                subtitle:
                    "für Premium Terrassendächer und Carports",
            },
        },
        menu: {
            terrace: 'Terrassendach',
            carport: 'Carport',
            help: 'Hilfe',
            home: 'Home',
        },
    },
    colors: {
        white: 'Weiß (RAL 9016)',
        weiss: 'Weiß (RAL 9016)',
        anthrazit: 'Anthrazit (RAL 7016)',
        ral: 'RAL ...',
    },
    covers: {
        pc_klar: 'Polycarbonat 16 mm klar',
        pc_bronce: 'Polycarbonat 16 mm bronce',
        pc_opal: 'Polycarbonat 16 mm opal',
        pc_weiss_opal: 'Polycarbonat klar weiss opal gestreift',
        acryl_klar: 'Acryl 16 mm klar',
        acryl_bronce: 'Acryl 16 mm bronce',
        acryl_klima: 'Acryl 16 mm Klima blue',
        glas: 'Echtglas 10 mm VSG',
    },
    terrace: {
        name: 'Terrassendach Konfigurator',
        title: 'Terrassenüberdachung Premium',
        desc: 'Wer höchste Ansprüche an Design und Qualität stellt, entscheidet sich mit dem Premium Terrassendach garantiert genau richtig.',
        config: 'Premium Terrassendach konfigurieren',
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
        objects: {
            title: 'Objektangaben',
            select_default_size: 'Standardgröße auswählen oder Maße für Sonderanfertigung eingeben',
            default_size: 'Standardgröße (B x T in mm)',
            special_size: 'Sonderanfertigung',
            width_in_mm: 'Breite in mm',
            length_in_mm: 'Tiefe in mm',
        },
        cover: {
            title: 'Bedachung',
        },
        color: {
            title: 'Farbe',
            title2: 'Farbe:',
        },
        wall: {
            title: 'Wandaufbau',
            select: 'bitte auswählen wenn bereits bekannt',
            beton: 'Befestigungsset Typ „Beton“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton2: 'Befestigungsset Typ „Beton 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton3: 'Befestigungsset Typ „Beton 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein“\nohne Dämmung.                Set für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton2: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton3: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
        },
        your_offer: 'Ihr Angebot',
        your_config: 'Terrassendach Konfiguration',
        mm: 'mm',
        roof: 'Bedachung:',
        price: 'UVP:',
        static: 'Statik / Montage',
        static_public: 'Statik / Gemeindespefzifische Bauordnung:',
        static_public2: 'Statik / Gemeindespefzifische Bauordnung',
        static_report: 'Statikbericht zur Vorlage bei der Gemeinde / Bauamt gewünscht',
        installation: 'Montage',
        installation_customer: 'Endkunde',
        installation_customer2: 'Montage durch Endkunden',
        installation_company: 'Fachfirma',
        installation_company2: 'Montage durch Fachfirma',
        installation_text: 'Bei der Montage durch eine Montagefirma fallen zusätzliche Kosten an, welche direkt von der Montagefirma berechnet werden. Montagepreise siehe „Aufmaß und Montageservice Premium Bauelemente“',
    },
    carport: {
        name: 'Carport Konfigurator',
        title: 'Carport Premium',
        desc: 'Carports in höchster Qualität – und mit individuellem Look\n' +
            '            Individualität, Design und Qualität.',
        config: 'Premium Carport konfigurieren',
        your_offer: 'Ihr Angebot',
        your_config: 'Carport Konfiguration',
        mm: 'mm',
        roof: 'Bedachung:',
        price: 'UVP:',
        installation: 'Montage',
        installation_customer: 'Endkunde',
        installation_customer2: 'Montage durch Endkunden',
        installation_company: 'Fachfirma',
        installation_company2: 'Montage durch Fachfirma',
        installation_text: 'Bei der Montage durch eine Montagefirma fallen zusätzliche Kosten an, welche direkt von der Montagefirma berechnet werden. Montagepreise siehe „Aufmaß und Montageservice Premium Bauelemente“',
        cover: {
            title: 'Bedachung',
        },
        color: {
            title: 'Farbe',
            title2: 'Farbe:',
        },
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
    },
    resources: {
        customers: {
            name: 'Kunde |||| Kunden',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                customerNo: 'Kunden-Nr.',
                customerNoName: 'Kunde',
                company: 'Firma',
                address: 'Adresse',
                country: 'Land',
                name: 'Name',
                name2: 'Name 2',
                name3: 'Name 3',
                zip: 'PLZ',
                city: 'Ort',
                phone: 'Telefon',
                email: 'E-Mail',
            },
            fieldGroups: {
                identity: 'Allgemein',
                address: 'Addresse',
                stats: 'Stats',
                visits: 'Besuche',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Kunden löschen',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        faq: {
            name: 'FAQ'
        },
        documentation: {
            name: 'Dokumentation'
        }
    },
    Unauthorized: 'Benutzername oder Passwort ungültig',
};

export default customGermanMessages;
