import { TranslationMessages } from 'ra-core';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        reset: 'Reset',
        calculate: 'Calculate',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            welcome: {
                title: 'Configurator APP',
                subtitle:
                    "for premium quality terrace roofing and carport",
            },
        },
        menu: {
            terrace: 'Terrace Roofing',
            carport: 'Carport',
            help: 'Help',
            home: 'Home',
        },
    },
    colors: {
        white: 'White (RAL 9016)',
        weiss: 'White (RAL 9016)',
        anthrazit: 'Anthracite (RAL 7016)',
        ral: 'RAL ...',
    },
    covers: {
        pc_klar: 'Polycarbonat 16 mm transparent',
        pc_bronce: 'Polycarbonat 16 mm bronce',
        pc_opal: 'Polycarbonat 16 mm opal',
        pc_weiss_opal: 'Polycarbonat transparent white opal stripes',
        acryl_klar: 'Acryl 16 mm transparent',
        acryl_bronce: 'Acryl 16 mm bronce',
        acryl_klima: 'Acryl 16 mm Klima blue',
        glas: 'Realglass 10 mm VSG',
    },
    terrace: {
        name: 'Terrace roofing configurator',
        title: 'Premium quality terrace roofing',
        desc: 'Those who demand the highest standards of design and quality are guaranteed \n' +
            '            to make the right choice with the Premium patio roof.',
        config: 'Configure Premium Terrace Roof',
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
        objects: {
            title: 'Object',
            select_default_size: 'Select standard size or enter custom configuration size',
            default_size: 'Standard size (W x D in mm)',
            special_size: 'Custom configuration',
            width_in_mm: 'Width in mm',
            length_in_mm: 'Depth in mm',
        },
        cover: {
            title: 'Cover',
        },
        color: {
            title: 'Color',
            title2: 'Color:',
        },
        wall: {
            title: 'Wall',
            select: 'please choose if already known',
            beton: 'Fixing set type concrete\nwithout insulation / set for 6 drill holes / fixing material.\nDIBT approval',
            beton2: 'Fixing set type concrete\nwith insulation up to 25cm / set for 6 drill holes / fixing material.\nDIBT approval',
            beton3: 'Fixing set type concrete\nwith insulation up to 32 cm / set for 6 drill holes / fixing material.\nDIBT approval',
            porenbeton: 'Fastening set type "aerated concrete / solid brick / perforated brick"\nwithout insulation / set for 6 drill holes / fixing material.\nDIBT approval',
            porenbeton2: 'Fastening set type "aerated concrete / solid brick / perforated brick"\nwith insulation up to 25cm / set for 6 drill holes / fixing material.\nDIBT approval',
            porenbeton3: 'Fastening set type "aerated concrete / solid brick / perforated brick"\nwith insulation up to 32 cm / set for 6 drill holes / fixing material.\nDIBT approval',
        },
        your_offer: 'Your configuration',
        your_config: 'Terrace roofing configuration',
        mm: 'mm',
        roof: 'Cover:',
        price: 'SRP:',
        static: 'Statics / Installation',
        static_public: 'Statics / Municipal building regulations:',
        static_public2: 'Statics / Municipal building regulations',
        static_report: 'Statics report desired for submission to the municipality / building authority',
        installation: 'Installation',
        installation_customer: 'Customer',
        installation_customer2: 'Assembly by end customer',
        installation_company: 'Company',
        installation_company2: 'Assembly by a specialized company',
        installation_text: 'For assembly by an assembly company, additional costs will be incurred, which will be charged directly by the assembly company. Installation prices see "Measurement and installation service Premium Bauelemente".',
    },
    carport: {
        name: 'Carport configurator',
        title: 'Carport Premium',
        desc: 'Carports in highest quality - and with individual look\n' +
            '            Individuality, design and quality.',
        config: 'Configure Premium Carport',
        your_offer: 'Your configuration',
        your_config: 'Premium carport configuration',
        mm: 'mm',
        roof: 'Cover:',
        price: 'SRP:',
        cover: {
            title: 'Cover',
        },
        color: {
            title: 'Color',
            title2: 'Color:',
        },
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
        installation: 'Installation',
        installation_customer: 'Customer',
        installation_customer2: 'Assembly by end customer',
        installation_company: 'Company',
        installation_company2: 'Assembly by a specialized company',
        installation_text: 'For assembly by an assembly company, additional costs will be incurred, which will be charged directly by the assembly company. Installation prices see "Measurement and installation service Premium Bauelemente".',

    },
    resources: {
        customers: {
            name: 'Kunde |||| Kunden',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                customerNo: 'Kunden-Nr.',
                customerNoName: 'Kunde',
                company: 'Firma',
                address: 'Adresse',
                country: 'Land',
                name: 'Name',
                name2: 'Name 2',
                name3: 'Name 3',
                zip: 'PLZ',
                city: 'Ort',
                phone: 'Telefon',
                email: 'E-Mail',
            },
            fieldGroups: {
                identity: 'Allgemein',
                address: 'Addresse',
                stats: 'Stats',
                visits: 'Besuche',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Kunden löschen',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        faq: {
            name: 'FAQ'
        },
        documentation: {
            name: 'Documentation'
        }
    },
};

export default customEnglishMessages;
