import React from "react";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Grid, Select, Switch, TextField } from "@material-ui/core";
import { useTranslate } from 'react-admin';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { StoreContainer } from "../../terraceStore";
import { ISize } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingBottom: '1rem',
    },
  }),
);


const TerraceObject = () => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const translate = useTranslate();

  if (store.isSizesLoading) return <div>Loading...</div>;
  //if (store.error) return <div>{store.error}</div>;

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="inherit" component="h2">
            {translate('terrace.objects.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <p>{translate('terrace.objects.select_default_size')}</p>
        <Typography  gutterBottom variant="inherit" component="h3">
            {translate('terrace.objects.default_size')}
        </Typography>
        <Select
          labelId="Standardgröße"
          id="size"
          value={store.stdSize}
          onChange={store.handleStdSizeChange}
          fullWidth
          native
        >
        {
          store.sizes &&
          store.sizes.length > 0 &&
          store.sizes.map((s: ISize) => <option key={s.key} value={s.key}>{s.width} x {s.length} mm</option>)
        }
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Typography  gutterBottom variant="inherit" component="h3">
            {translate('terrace.objects.special_size')}
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={store.isConfigurable} onChange={store.toggleIsConfigurable} />}
            label={translate('terrace.objects.special_size')}
          />
        </Grid>
        {store.isConfigurable &&
          <Grid container spacing={2}>
            <Grid item sm={4} xs={6}>
              <TextField
              id="width" label={translate('terrace.objects.width_in_mm')}
              value={store.width} onChange={store.setWidthValue}
            />
            </Grid>
            <Grid item sm={4} xs={6}>
              <TextField
                id="length" label={translate('terrace.objects.length_in_mm')}
                value={store.length} onChange={store.setLengthValue}
              />
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  )
};

export default TerraceObject;
