import { TranslationMessages } from 'ra-core';
import romanianMessages from 'ra-language-romanian';

const customRomanianMessages: TranslationMessages = {
    ...romanianMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Limba',
        reset: 'Resetare la valorile implicite',
        calculate: 'Calcul',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            welcome: {
                title: 'Configurator APP',
                subtitle:
                    "pentru acoperișuri premium de terasă și carporturi",
            },
        },
        menu: {
            terrace: 'Acoperire de terasă',
            carport: 'Carport',
            help: 'Help',
            home: 'Home',
        },
    },
    colors: {
        white: 'Alb (RAL 9016)',
        weiss: 'Alb (RAL 9016)',
        anthrazit: 'Anthracit (RAL 7016)',
        ral: 'RAL ...',
    },
    covers: {
        pc_klar: 'Policarbonat 16 mm clar',
        pc_bronce: 'Policarbonat 16 mm bronz',
        pc_opal: 'Policarbonat 16 mm opal',
        pc_weiss_opal: 'Policarbonat cu dungi albe opal',
        acryl_klar: 'Acryl 16 mm clar',
        acryl_bronce: 'Acryl 16 mm bronz',
        acryl_klima: 'Acryl 16 mm albastru Klima',
        glas: 'Sticla reală de securitate laminată de 10 mm',
    },
    terrace: {
        name: 'Configurator acoperiș terasa',
        title: 'Configurație acoperiș terasa',
        desc: 'Cei care solicită cele mai inalte cerințe în materie de design \nși calitate au garanția de a lua decizia corectă cu acoperișul premium de terasă.',
        config: 'Configurați acoperișul premium de terasă',
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
        objects: {
            title: 'Detalii despre proprietate',
            select_default_size: 'Selectați dimensiunea standard sau introduceți măsurători pentru produse la comandă',
            default_size: 'Dimensiune standard (L x P în mm)',
            special_size: 'Comandă personalizată',
            width_in_mm: 'Latime în mm',
            length_in_mm: 'Adâncime în mm',
        },
        cover: {
            title: 'Acoperire',
        },
        color: {
            title: 'Culoare',
            title2: 'Culoare:',
        },
        wall: {
            title: 'Construcția pereților',
            select: 'Vă rugăm să selectați dacă sunteți deja inregistrat',
            beton: 'Befestigungsset Typ „Beton“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton2: 'Befestigungsset Typ „Beton 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton3: 'Befestigungsset Typ „Beton 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton2: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton3: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
        },
        your_offer: 'Oferta ta',
        your_config: 'Acoperire premium de terasă',
        mm: 'mm',
        roof: 'Acoperire:',
        price: 'SRP:',
        static: 'Plan de structura / Montaj',
        static_public: 'Plan de structura / reglementări municipale de construcții:',
        static_public2: 'Plan de structura / reglementări municipale de construcții',
        static_report: 'Raportul planului de structura necesar pentru transmiterea către municipalitate / avizarea de constructie',
        installation: 'Montaj',
        installation_customer: 'Clienții finali',
        installation_customer2: 'Asamblare de către clienții finali',
        installation_company: 'Companie specoalitate',
        installation_company2: 'Asamblare de către o companie de specialitate',
        installation_text: 'La instalarea de către o companie de montaj, sunt suportate costuri suplimentare, care sunt calculate direct de către compania de montaj. Pentru prețuri de asamblare, consultați "Serviciul de măsurare și asamblare pentru componente premium"',
    },
    carport: {
        name: 'Configurator Carport',
        title: 'Carport Premium',
        desc: 'Carporturi de cea mai înaltă calitate - \nși cu un aspect individual Individualitate, design și calitate.',
        config: 'Configurați Carport Premium',
        your_offer: 'Oferta ta',
        your_config: 'Acoperire premium de carport',
        mm: 'mm',
        roof: 'Acoperire:',
        price: 'SRP:',
        cover: {
            title: 'Acoperire',
        },
        color: {
            title: 'Culoare',
            title2: 'Culoare:',
        },
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
    },
    resources: {
        customers: {
            name: 'Kunde |||| Kunden',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                customerNo: 'Kunden-Nr.',
                customerNoName: 'Kunde',
                company: 'Firma',
                address: 'Adresse',
                country: 'Land',
                name: 'Name',
                name2: 'Name 2',
                name3: 'Name 3',
                zip: 'PLZ',
                city: 'Ort',
                phone: 'Telefon',
                email: 'E-Mail',
            },
            fieldGroups: {
                identity: 'Allgemein',
                address: 'Addresse',
                stats: 'Stats',
                visits: 'Besuche',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Kunden löschen',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        faq: {
            name: 'FAQ'
        },
        documentation: {
            name: 'Documentation'
        }
    },
};

export default customRomanianMessages;
