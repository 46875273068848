//import { TranslationMessages } from 'ra-core';
import hungarianMessages from 'ra-language-hungarian';

const customHungarianMessages = {
    ...hungarianMessages,
    pos: {
        search: 'Suche',
        configuration: 'Konfiguration',
        language: 'Sprache',
        reset: 'Visszaállítás alapértelmezettre',
        calculate: 'Számolni',
        theme: {
            name: 'Thema',
            light: 'Hell',
            dark: 'Dunkel',
        },
        dashboard: {
            welcome: {
                title: 'Configurator APP',
                subtitle:
                    "prémium terasztetőkhöz és autóbeállókhoz",
            },
        },
        menu: {
            terrace: 'Terasztető',
            carport: 'Autóbeálló',
            help: 'Hilfe',
            home: 'Home',
        },
    },
    colors: {
        white: 'Fehér (RAL 9016)',
        weiss: 'Fehér (RAL 9016)',
        anthrazit: 'Antracit (RAL 7016)',
        ral: 'RAL ...',
    },
    covers: {
        pc_klar: 'Polikarbonát 16 mm átlátszó',
        pc_bronce: 'Polikarbonát 16 mm-es bronz',
        pc_opal: 'Polikarbonát 16 mm-es opál',
        pc_weiss_opal: 'Polikarbonát átlátszó fehér opál csíkokkal',
        acryl_klar: 'Akryl 16 mm átlátszó',
        acryl_bronce: 'Akryl 16 mm-es bronz',
        acryl_klima: 'Akryl 16 mm kék',
        glas: '10 mm-es laminált biztonsági üveg',
    },
    terrace: {
        name: 'Terasztető konfigurátor',
        title: 'Terasz borító Premium',
        desc: 'Azok számára, akik a legmagasabb igényeket támasztják alá a tervezést és a minőséget, \ngarantáltan meghozzák a helyes döntést a prémium terasztetővel.',
        config: 'A prémium terasztető konfigurálása',
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
        objects: {
            title: 'Információ a tárgyról',
            select_default_size: 'Válassza ki a szabványos méretet, vagy adja meg a méretet az egyedi gyártású termékekhez',
            default_size: 'Szabványméret (Sz x M mm-ben)',
            special_size: 'Egyéni megrendelés',
            width_in_mm: 'Szélesség mm-ben',
            length_in_mm: 'Mélység mm-ben',
        },
        cover: {
            title: 'Tetőszerkezet',
        },
        color: {
            title: 'Szín',
            title2: 'Szín:',
        },
        wall: {
            title: 'Falszerkezet',
            select: 'Kérjük, válassza ki, ha már ismert',
            beton: 'Befestigungsset Typ „Beton“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton2: 'Befestigungsset Typ „Beton 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton3: 'Befestigungsset Typ „Beton 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton2: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton3: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
        },
        your_offer: 'Az Ön ajánlata',
        your_config: 'Terasztető konfigurátor',
        mm: 'mm',
        roof: 'Tetőszerkezet:',
        price: 'UVP:',
        static: 'Statika / összeszerelés',
        static_public: 'Statika / Önkormányzati építési szabályzat:',
        static_public2: 'Statika / Önkormányzati építési szabályzat',
        static_report: 'Statikus jelentés, amelyet benyújtani kell az önkormányzatnak / építési hatóságnak',
        installation: 'Összeszerelés',
        installation_customer: 'Által',
        installation_customer2: 'Összeszerelés a végfelhasználók által',
        installation_company: 'Speciális cégnél',
        installation_company2: 'Összeszerelés egy speciális cégnél',
        installation_text: 'Az összeszerelő cég által végzett összeszerelés esetén további költségek merülnek fel, amelyeket közvetlenül az összeszerelőt terheli. Az összeszerelési árak a "Mérési és összeszerelési szolgáltatás prémium alkatrészei" részben találhatók.',
    },
    carport: {
        name: 'Autóbeálló konfigurátor',
        title: 'Autóbeálló Premium',
        desc: 'Autóbeálló kiváló minőségben - egyedi megjelenéssel.\n Individualitás, forma és minőség',
        config: 'Konfigurálja a prémium carportot',
        your_offer: 'Az Ön ajánlata',
        your_config: 'Autóbeálló konfigurátor',
        mm: 'mm',
        roof: 'Tetőszerkezet:',
        price: 'UVP:',
        cover: {
            title: 'Tetőszerkezet',
        },
        color: {
            title: 'Szín',
            title2: 'Szín:',
        },
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
    },
    resources: {
        customers: {
            name: 'Kunde |||| Kunden',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                customerNo: 'Kunden-Nr.',
                customerNoName: 'Kunde',
                company: 'Firma',
                address: 'Adresse',
                country: 'Land',
                name: 'Name',
                name2: 'Name 2',
                name3: 'Name 3',
                zip: 'PLZ',
                city: 'Ort',
                phone: 'Telefon',
                email: 'E-Mail',
            },
            fieldGroups: {
                identity: 'Allgemein',
                address: 'Addresse',
                stats: 'Stats',
                visits: 'Besuche',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Kunden löschen',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        faq: {
            name: 'FAQ'
        },
        documentation: {
            name: 'Dokumentation'
        }
    },
    Unauthorized: 'Benutzername oder Passwort ungültig',
};

export default customHungarianMessages;
