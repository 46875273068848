import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
//import HelpIcon from "@material-ui/icons/Help";
//import DescriptionIcon from "@material-ui/icons/Description";
import HomeIcon from "@material-ui/icons/Home";
import StoreIcon from "@material-ui/icons/Store";
import StorefrontIcon from "@material-ui/icons/Storefront";
//import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { Divider, Theme, useMediaQuery } from "@material-ui/core";
import { DashboardMenuItem, MenuItemLink, useTranslate } from "react-admin";
//import SubMenu from "./SubMenu";
import { AppState } from "../types";

type MenuName = 'menuTerrace' | 'menuCarport' | 'menuCustomers' | 'menuHelp';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuTerrace: false,
        menuCarport: false,
        menuCustomers: false,
        menuHelp: false,
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    /*
    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    */

    return (
        <div>
            {' '}
            {
                <DashboardMenuItem
                  primaryText={translate(`pos.menu.home`, {
                    smart_count: 1,
                  })}
                  leftIcon={<HomeIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
            }
            <Divider light />
            <MenuItemLink
                to={`/terrace`}
                primaryText={translate(`pos.menu.terrace`, {
                    smart_count: 1,
                })}
                leftIcon={<StorefrontIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/carport`}
                primaryText={translate(`pos.menu.carport`, {
                    smart_count: 1,
                })}
                leftIcon={<StoreIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {' '}
            {/*
            <Divider light />
            <SubMenu
                handleToggle={() => handleToggle('menuHelp')}
                isOpen={state.menuHelp}
                sidebarIsOpen={open}
                name="pos.menu.help"
                icon={<HelpIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/help/documentation`}
                    primaryText={translate(`resources.documentation.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<DescriptionIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/help/faq`}
                    primaryText={translate(`resources.faq.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<QuestionAnswerIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            */}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
