import { useEffect, useState } from "react";
import { httpClient } from "./index";
import { useDebounce } from "use-debounce";

export const useFetchCarportPrice = (setStdSizeChange) => {
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [size, setSize] = useState('3094x5620');
  const [color, setColor] = useState('anthrazit');
  const [cover, setCover] = useState('acryl_klar');

  const [width, setWidth] = useState('');
  const [debouncedWidth] = useDebounce(width, 1000);
  const [length, setLength] = useState('');
  //const [configurableSize, setConfigurableSize] = useState('');
  const [isConfigurable, setIsConfigurable] = useState(false);
  const [debouncedLength] = useDebounce(length, 1000);
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(null);

    let wl = size.split('x');

    if (!isConfigurable) {
      setWidth('');
      setLength('');
    }

    if (debouncedLength && parseInt(debouncedLength) > 5060) {
      setLength('5060');
      return;
    }
    if (debouncedWidth && parseInt(debouncedWidth) > 10140) {
      setWidth('10140');
      return;
    }

    if (isConfigurable && debouncedLength !== "" && debouncedWidth !== "") {
      sizes.some((s) => {
        if (s.length >= debouncedLength && s.width >= debouncedWidth) {
          setSize(s.width + "x" + s.length);
          setStdSizeChange(s.width + "x" + s.length);
          wl = [s.width, s.length];
          return true;
        }
      });
    }

    const data = {
      width: wl[0],
      length: wl[1],
      color: color,
      cover: cover,
      configurable: isConfigurable,
    };

    httpClient('/api/carport/price', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        setLoading(false);
        if (json) {
          setPrice(json)
        } else {
          setPrice(null)
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      })
  }, [size, color, cover, isConfigurable, debouncedWidth, debouncedLength]);
  return [{
    price, isDataLoading: loading, isDataError: error,
    size,
    width, setWidth,
    length, setLength,
    isConfigurable,
  },
    setSize, setColor, setCover, setSizes, setIsConfigurable
  ]
};
