import React from 'react';
import Typography from "@material-ui/core/Typography";
import {
  FormControlLabel,
  Grid,
  Radio, RadioGroup,
  Switch,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { StoreContainer } from "../../terraceStore";
import {useTranslate} from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      //backgroundColor: '#f9f9f9',
    },
    radio: {
      '&&': {
        position: 'absolute',
        opacity: 0,
        width: 0,
        height: 0,
      },
      '&& + img': {
        cursor: 'pointer',
      },
      '&&:checked + img': {
        outline: '2px solid #37474f',
      },
      '&& + img + div': {
        cursor: 'pointer',
      },
      '&&:checked + img + div': {
        backgroundColor: '#37474f',
        color: '#fff',
      },
    },
    media: {
      height: 100,
    },
  }),
);

const TerraceService = () => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const translate = useTranslate();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="inherit" component="h2">
          {translate('terrace.static')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography  gutterBottom variant="inherit" component="h3">
            {translate('terrace.static_public2')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
        control={<Switch checked={store.serviceStatic} onChange={store.toggleServiceStatic} />}
        label={translate('terrace.static_report')}
      />
      </Grid>
      <Grid item xs={12}>
        <Typography  gutterBottom variant="inherit" component="h3">
            {translate('terrace.installation')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup aria-label="serviceMontage" name="serviceMontage" value={store.serviceMontage} onChange={store.handleChangeServiceMontage}>
          <FormControlLabel
            value="Endkunde"
            control={<Radio color="primary" />}
            label={translate('terrace.installation_customer2')}
            labelPlacement="end"
          />
          <FormControlLabel
            value="Fachfirma"
            control={<Radio color="primary" />}
            label={translate('terrace.installation_company2')}
            labelPlacement="end"
          />
        </RadioGroup>

          {translate('terrace.installation_text')}
      </Grid>
    </Grid>
  )
};

export default TerraceService;
