import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Filter, SearchInput, TextInput, Title, useTranslate, useAuthenticated } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import Logo from "../layout/Logo";
import { Link as RouterLink } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";

const responsiveImage =  require('../assets/images/Carports/4296309_Carport_anthrazit_Acryl_klar.jpg?placeholder=true&sizes[]=300,sizes[]=600,sizes[]=800,sizes[]=1200,sizes[]=1600');

const Separator = () => <Box pt="0.5em" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    card: {
      //maxWidth: 600,
    },
  }),
);

function getSteps() {
  return ['Farbe', 'Zubehör', 'Montage'];
}

const DachForm = (props: any) => (
  <form noValidate>
    <Typography variant="h6" gutterBottom>
      Größe
    </Typography>
    <Select
      labelId="Größe"
      id="abm"
      fullWidth
    >
      <MenuItem value={'Polycarbonat SO Klar'}>Polycarbonat SO Klar</MenuItem>
      <MenuItem value={'Polycarbonat Bronce'}>Polycarbonat Bronce</MenuItem>
      <MenuItem value={'Polycarbonat Opal'}>Polycarbonat Opal</MenuItem>
      <MenuItem value={'Polycarbonat klar - weiss gestreift'}>Polycarbonat klar - weiss gestreift</MenuItem>
      <MenuItem value={'Acryl klar'}>Acryl klar</MenuItem>
      <MenuItem value={'Acryl bronce'}>Acryl bronce</MenuItem>
      <MenuItem value={'Acryl Klima blue'}>Acryl Klima blue</MenuItem>
      <MenuItem value={'Glas VSG 10 mm'}>Glas VSG 10 mm</MenuItem>
    </Select>
  </form>
);

const Bedachung = (props: any) => {
  const [color, setColor] = React.useState('');

  React.useEffect(() => {
    //setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setColor(event.target.value as string);
  };

  return (
    <form noValidate>
      <Typography variant="h6" gutterBottom>
        Bedachung
      </Typography>

      <Select
        labelId="Farbe"
        id="color"
        value={color}
        onChange={handleChange}
        fullWidth
      >
        <MenuItem value={'Polycarbonat SO Klar'}>Polycarbonat SO Klar</MenuItem>
        <MenuItem value={'Polycarbonat Bronce'}>Polycarbonat Bronce</MenuItem>
        <MenuItem value={'Polycarbonat Opal'}>Polycarbonat Opal</MenuItem>
        <MenuItem value={'Polycarbonat klar - weiss gestreift'}>Polycarbonat klar - weiss gestreift</MenuItem>
        <MenuItem value={'Acryl klar'}>Acryl klar</MenuItem>
        <MenuItem value={'Acryl bronce'}>Acryl bronce</MenuItem>
        <MenuItem value={'Acryl Klima blue'}>Acryl Klima blue</MenuItem>
        <MenuItem value={'Glas VSG 10 mm'}>Glas VSG 10 mm</MenuItem>
      </Select>

    </form>
  );
};

const Farbe = (props: any) => (
  <form noValidate>
    <Typography variant="h6" gutterBottom>
      Farbe
    </Typography>
    <Select
      labelId="Farbe"
      id="color"
      fullWidth
    >
      <MenuItem value={'weiss'}>weiß</MenuItem>
      <MenuItem value={'anthrazit'}>anthrazit</MenuItem>
    </Select>
  </form>
);

const Zubehoer = (props: any) => (
  <form noValidate>
    <Typography variant="h6" gutterBottom>
      Zubehör
    </Typography>
    <FormControlLabel
      control={
        <Checkbox value="" />
      }
      label="Sichtschutz"
    />
    <Separator />
    <FormControlLabel
      control={
        <Checkbox value="" />
      }
      label="Pfostenanker"
    />
    <Separator />
    <FormControlLabel
      control={
        <Checkbox value="" />
      }
      label="Schneeräumer für das Dach"
    />
  </form>
);


const Befestigung = (props: any) => (
  <form noValidate>
    <Typography variant="h6" gutterBottom>
      Befestigung
    </Typography>
    <FormControlLabel
      control={
        <Checkbox value="BESCHATTUNG MIT SEILFÜHRUNG" />
      }
      label="Aufdachmontage"
    />
    <Separator />
    <FormControlLabel
      control={
        <Checkbox value="Aufdachmarkise San Remo" />
      }
      label="Balkonmontage"
    />
    <Separator />
    <FormControlLabel
      control={
        <Checkbox value="Aufdachmarkise San Remo light" />
      }
      label="Gebäudenische"
    />
    <Separator />
    <FormControlLabel
      control={
        <Checkbox value="Unterdachmarkise St. Tropez" />
      }
      label="Hausversatz, Hausvorsprung"
    />
  </form>
);


const Montage = (props: any) => (
  <form noValidate>
    <Typography variant="h6" gutterBottom>
      Montage
    </Typography>
    <FormControlLabel
      control={
        <Checkbox value="BESCHATTUNG MIT SEILFÜHRUNG" />
      }
      label="Aufdachmontage"
    />
    <Separator />
    <FormControlLabel
      control={
        <Checkbox value="Aufdachmarkise San Remo" />
      }
      label="Montage durch Fachfirma"
    />
    <Separator />
    <FormControlLabel
      control={
        <Checkbox value="Aufdachmarkise San Remo light" />
      }
      label="Eingenleistung"
    />
  </form>
);



function getStepContent(stepIndex: number) {
  switch (stepIndex) {
    //case 0:
    //  return <DachForm />;
    //case 1:
    //  return <Bedachung />;
    case 0:
      return <Farbe />;
    case 1:
      return <Zubehoer />;
    //case 2:
    //  return <Befestigung />;
    case 2:
      return <Montage />;
    default:
      return 'Ungültig';
  }
}
const TerraceList = (props: any) => {
  useAuthenticated();
  const classes = useStyles();
  const translate = useTranslate();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Card>
      <Title title={translate('carport_steps.name',
        {
          smart_count: 1,
        })} />
      <CardContent>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Grid container>

              {/*
            <div style={{
              width: 300,
              backgroundSize: 'cover',
              backgroundImage: 'url("' + responsiveImage.placeholder + '")'
            }}>
              <img src={responsiveImage.src} srcSet={responsiveImage.srcSet} />
            </div>
            */}
              <Grid container spacing={5}>
                <Grid item xs={12} sm={8}>
                  <Card className={classes.card}>
                    <CardMedia
                      component="img"
                      alt=""
                      //height="600"
                      image={responsiveImage.src}
                      srcSet={responsiveImage.srcSet}
                      title=""
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        UVP:   3.299,00 €
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        CARPORT PREMIUM ANTHRAZIT	3094x5620	mm
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography className={classes.instructions}>Ihr Angebot</Typography>
                </Grid>
              </Grid>



              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <Button onClick={handleReset}>Zurücksetzen</Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid container>

            {/*
            <div style={{
              width: 300,
              backgroundSize: 'cover',
              backgroundImage: 'url("' + responsiveImage.placeholder + '")'
            }}>
              <img src={responsiveImage.src} srcSet={responsiveImage.srcSet} />
            </div>
            */}
            <Grid container spacing={5}>
              <Grid item xs={12} sm={8}>
                <Card className={classes.card}>
                    <CardMedia
                      component="img"
                      alt=""
                      //height="600"
                      image={responsiveImage.src}
                      srcSet={responsiveImage.srcSet}
                      title=""
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        UVP:   3.299,00 €
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        CARPORT PREMIUM ANTHRAZIT	3094x5620	mm
                      </Typography>
                    </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                {getStepContent(activeStep)}
              </Grid>
            </Grid>



            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Zurück
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Berechnen' : 'Weiter'}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
      </CardContent>
    </Card>
  );
};

export default TerraceList;
