import React from "react";
import Typography from "@material-ui/core/Typography";
import { Card, CardActionArea, CardContent, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import { StoreContainer } from "../../terraceStore";
import {useTranslate} from "react-admin";

const wall1 =  require('../../assets/images/Befestigungsset_1_Beton_FINAL.jpg?placeholder=true&sizes[]=320');
const wall2 =  require('../../assets/images/AMO-Therm_Beton_FINAL.jpg?placeholder=true&sizes[]=320');
const wall3 =  require('../../assets/images/AMO-Therm_32_Beton_FINAL.jpg?placeholder=true&sizes[]=320');
const wall4 =  require('../../assets/images/AMO-_Porenbeton-Lochstein_ohne_Daemmung_1_FINAL.jpg?placeholder=true&sizes[]=320');
const wall5 =  require('../../assets/images/AMO-Therm_Porenbeton-Lochstein_FINAL.jpg?placeholder=true&sizes[]=320');
const wall6 =  require('../../assets/images/AMO-Therm_32_Porenbeton-Lochstein_FINAL.jpg?placeholder=true&sizes[]=320');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      backgroundColor: '#f9f9f9',
    },
    radio: {
      '&&': {
        position: 'absolute',
        opacity: 0,
        width: 0,
        height: 0,
      },
      '&& + img': {
        cursor: 'pointer',
      },
      '&&:checked + img': {
        outline: '2px solid #37474f',
      },
      '&& + img + div': {
        cursor: 'pointer',
      },
      '&&:checked + img + div': {
        backgroundColor: '#37474f',
        color: '#fff',
      },
    },
    media: {
      height: 100,
    },
    whiteSpace: {
      whiteSpace: 'pre-line',
    }
  }),
);

const TerraceWall = () => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const translate = useTranslate();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="inherit" component="h2">
          {translate('terrace.wall.title')}
        </Typography>
        {translate('terrace.wall.select')}
      </Grid>
      <Grid item sm={6} xs={6}>
        <Card>
          <CardActionArea onClick={() => store.handleWallValueChange('beton')}>
            <label>
              <input type="radio" className={classes.radio} name="wall" value="beton" checked={store.wall === 'beton'} />
              <CardMedia
                component="img"
                alt=""
                className={classes.media}
                image={wall1.src}
                srcSet={wall1.srcSet}
                title=""
              />
              <CardContent className={classes.whiteSpace}>
                {translate('terrace.wall.beton')}
              </CardContent>
            </label>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item sm={6} xs={6}>
        <Card>
          <CardActionArea onClick={() => store.handleWallValueChange('beton2')}>
            <label>
              <input type="radio" className={classes.radio} name="wall" value="beton2" checked={store.wall === 'beton2'} />
              <CardMedia
                component="img"
                alt=""
                className={classes.media}
                image={wall2.src}
                srcSet={wall2.srcSet}
                title=""
              />
              <CardContent className={classes.whiteSpace}>
                {translate('terrace.wall.beton2')}
              </CardContent>
            </label>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item sm={6} xs={6}>
        <Card>
          <CardActionArea onClick={() => store.handleWallValueChange('beton3')}>
            <label>
              <input type="radio" className={classes.radio} name="wall" value="beton3" checked={store.wall === 'beton3'} />
              <CardMedia
                component="img"
                alt=""
                className={classes.media}
                image={wall3.src}
                srcSet={wall3.srcSet}
                title=""
              />
              <CardContent className={classes.whiteSpace}>
                {translate('terrace.wall.beton3')}
              </CardContent>
            </label>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item sm={6} xs={6}>
        <Card>
          <CardActionArea onClick={() => store.handleWallValueChange('porenbeton')}>
            <label>
              <input type="radio" className={classes.radio} name="wall" value="porenbeton" checked={store.wall === 'porenbeton'} />
              <CardMedia
                component="img"
                alt=""
                className={classes.media}
                image={wall4.src}
                srcSet={wall4.srcSet}
                title=""
              />
              <CardContent className={classes.whiteSpace}>
                {translate('terrace.wall.porenbeton')}
              </CardContent>
            </label>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item sm={6} xs={6}>
        <Card>
          <CardActionArea onClick={() => store.handleWallValueChange('porenbeton2')}>
            <label>
              <input type="radio" className={classes.radio} name="wall" value="porenbeton2" checked={store.wall === 'porenbeton2'} />
              <CardMedia
                component="img"
                alt=""
                className={classes.media}
                image={wall5.src}
                srcSet={wall5.srcSet}
                title=""
              />
              <CardContent className={classes.whiteSpace}>
                {translate('terrace.wall.porenbeton2')}
              </CardContent>
            </label>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item sm={6} xs={6}>
        <Card>
          <CardActionArea onClick={() => store.handleWallValueChange('porenbeton3')}>
            <label>
              <input type="radio" className={classes.radio} name="wall" value="porenbeton3" checked={store.wall === 'porenbeton3'} />
              <CardMedia
                component="img"
                alt=""
                className={classes.media}
                image={wall6.src}
                srcSet={wall6.srcSet}
                title=""
              />
              <CardContent className={classes.whiteSpace}>
                {translate('terrace.wall.porenbeton3')}
              </CardContent>
            </label>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  )
};

export default TerraceWall;
