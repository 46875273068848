import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Title, useAuthenticated, useTranslate } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { StoreContainer } from "../terraceStore"

import TerraceObject from "./components/TerraceObject";
import TerraceColor from "./components/TerraceColor";
import TerraceCover from "./components/TerraceCover";
import TerraceWall from "./components/TerraceWall";
import TerraceService from "./components/TerraceService";
import Sticky from 'react-sticky-el';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    card: {
      //maxWidth: 600,
      //position: 'sticky',
      top: 10,
      zIndex: 1000,
    },
    planner: {
      overflow: 'auto',
    },
    sticky: {
      position: 'sticky',
      top: 0,
    }
  }),
);

function getSteps() {
  return ['Objektangaben', 'Farbe', 'Bedachung', 'Zubehör', 'Wandaufbau', 'Montage']; //['Dachgröße', 'Bedachung', 'Farbe', 'Zubehör', 'Befestigung', 'Montage'];
}

const DachForm = (props: any) => (
  <form noValidate>
      <TerraceObject />
      <TerraceColor />
      <TerraceCover />
      {/*<CarportAccessory />*/}
      <TerraceWall />
      <TerraceService />
  </form>
);

const TerraceCalculation = (props: any) => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const translate = useTranslate();

  return (
      <Grid item xs={12} sm={6} className={classes.planner}>
        <Typography gutterBottom variant="inherit" component="h2">
            {translate('terrace.your_offer')}
        </Typography>
        <p/>
        <Typography gutterBottom variant="inherit" component="h4">
            {translate('terrace.your_config')}
        </Typography>
        <Typography variant="body2" component="p">
            {translate('terrace.title')} {store.stdSize} {translate('terrace.mm')}<br/>
            {translate('terrace.roof')} {translate(`covers.${store.cover}`)}<br/>
            {translate('terrace.color.title2')} {translate(`colors.${store.color}`)}
        </Typography>
        <p/>

        <Typography gutterBottom variant="inherit" component="h4">
            {translate('terrace.static')}
        </Typography>
        <Typography variant="body2" component="p">
            {translate('terrace.static_public')} {(store.serviceStatic) ? translate('ra.message.yes') : translate('ra.message.no')}<br/>
        </Typography>

        <Typography variant="body2" component="p">
            {translate('terrace.installation')}: {(store.serviceMontage === 'Fachfirma') ? translate('terrace.installation_company') : translate('terrace.installation_customer')}
            <br/>
        </Typography>
        {(store.serviceMontage === 'Fachfirma') ? (
          <Typography variant="body2" color="textSecondary" component="p">
              {translate('terrace.installation_text')}
          </Typography>
          ) : ''}
        <p/>
        <Typography gutterBottom variant="h5" component="h2">
            {translate('terrace.price')} {(store.price && store.price.price) ? store.price.price.toLocaleString() : ''} €
        </Typography>
      </Grid>
  );
};

const PreviewImage = (props: any) => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const translate = useTranslate();

  return (
      <Sticky className={classes.card}>
        <Card>
          <CardMedia
            component="img"
            alt=""
            //height="600"
            image={(store.images) ? store.images.image : '/assets/images/'}
            srcSet={(store.images) ? store.images.srcSetImage : null}
            title=""
          />
          {props.step === 0 ? (
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
                {translate('terrace.price')} {(store.price && store.price.price) ? store.price.price.toLocaleString() : ''} €
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {translate('terrace.title')} {store.stdSize} {translate('terrace.mm')}<br/>
              {translate('terrace.roof')} {translate(`covers.${store.cover}`)}<br/>
              {translate('terrace.color.title2')} {translate(`colors.${store.color}`)}
            </Typography>
          </CardContent>
          ) : ('')}
        </Card>
      </Sticky>
  );
};

function getStepContent(stepIndex: number) {
  switch (stepIndex) {
    case 0:
    //case 1:
    //case 2:
    //case 3:
    //case 4:
      return <DachForm />;
    default:
      return <TerraceCalculation />;
  }
}
const TerraceList = (props: any) => {
  useAuthenticated();
  const classes = useStyles();
  const translate = useTranslate();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  /*
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  */

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Card>
      <Title title={translate('terrace.name',
        {
          smart_count: 1,
        })} />
      <CardContent>
      <div>
        <StoreContainer.Provider>
        {activeStep > 0 ? (
          <div>
            <Grid container>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <PreviewImage step={activeStep} />
                    </Grid>
                    <TerraceCalculation />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Button onClick={handleReset}>{translate('pos.reset')}</Button>
                  </Grid>
              </Grid>
          </div>
        ) : (
          <Grid container>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={7}>
                    <PreviewImage step={activeStep} />
                </Grid>

                <Grid item xs={12} sm={5} className={classes.planner}>
                  {getStepContent(activeStep)}
                </Grid>
              </Grid>

            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? translate('pos.calculate') : translate('pos.calculate')}
              </Button>
            </Grid>
          </Grid>
        )}
        </StoreContainer.Provider>
      </div>
      </CardContent>
    </Card>
  );
};

export default TerraceList;
