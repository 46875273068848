import React, { useEffect, useState } from "react";
import { Admin, Resource, resolveBrowserLocale } from "react-admin";

import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Layout, Login } from "./layout";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";

// i18n
import polyglotI18nProvider from "ra-i18n-polyglot";
import enMessages from "./i18n/en";
import deMessages from "./i18n/de";
import roMessages from "./i18n/ro";
import czMessages from "./i18n/cz";
import plMessages from "./i18n/pl";
import huMessages from "./i18n/hu";

import dataProviderFactory from "./dataProvider";
import closeSidebarSaga from "./closeSidebarSaga";

let language = localStorage.getItem('language');
if (!language) {
    language = resolveBrowserLocale();
    if (!language)
        language = 'en';
    localStorage.setItem('language', language);
}

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'de') {
        return deMessages; //import('./i18n/de').then(messages => messages.default);
    }
    else if (locale === 'ro') {
        return roMessages; //import('./i18n/ro').then(messages => messages.default);
    }
    else if (locale === 'cz') {
        return czMessages; //import('./i18n/cz').then(messages => messages.default);
    }
    else if (locale === 'pl') {
        return plMessages; //import('./i18n/pl').then(messages => messages.default);
    }
    else if (locale === 'hu') {
        return huMessages; //import('./i18n/hu').then(messages => messages.default);
    }

    // Always fallback on english
    return enMessages;
}, language);

const App = () => {
    const [dataProvider, setDataProvider] = useState(null);

    useEffect(() => {
        let restoreFetch;

        const fetchDataProvider = async () => {
            const dataProviderInstance = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            );
            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            customSagas={[ closeSidebarSaga ]}
        >
            <Resource/>
        </Admin>
    );
};

export default App;
