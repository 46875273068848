//import { TranslationMessages } from 'ra-core';
import polishMessages from 'ra-language-polish';

const customPolishMessages = {
    ...polishMessages,
    pos: {
        search: 'Suche',
        configuration: 'Konfiguration',
        language: 'Sprache',
        reset: 'Przywróć ustawienia domyślne',
        calculate: 'Przelicz',
        theme: {
            name: 'Thema',
            light: 'Hell',
            dark: 'Dunkel',
        },
        dashboard: {
            welcome: {
                title: 'Configurator APP',
                subtitle:
                    "Dla zadaszeń Premium i wiat",
            },
        },
        menu: {
            terrace: 'Zadaszenia tarasu',
            carport: 'Wiata',
            help: 'Hilfe',
            home: 'Home',
        },
    },
    colors: {
        white: 'Biały (RAL 9016)',
        weiss: 'Biały (RAL 9016)',
        anthrazit: 'Antracyt (RAL 7016)',
        ral: 'RAL ...',
    },
    covers: {
        pc_klar: 'Poliwęglan 16mm bezbarwny',
        pc_bronce: 'Poliwęglan 16mm brązowy',
        pc_opal: 'Poliwęglan 16mm opal',
        pc_weiss_opal: 'Poliwęglan z opalizującymi paskami',
        acryl_klar: 'Akryl 16mm bezbarwny',
        acryl_bronce: 'Akryl 16mm brązowy',
        acryl_klima: 'Akryl 16 mm Klima blue (błękitny)',
        glas: 'Szkło bepieczne laminowane 10mm',
    },
    terrace: {
        name: 'Konfigurator zadaszenia tarasu',
        title: 'Zadaszenie tarasu Premium',
        desc: 'Podejmując decyzję o wyborze zadaszenia tarasu Premium masz gwarancję wzornictwa i jakości',
        config: 'Skonfiguruj zadaszenie tarasu Premium',
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
        objects: {
            title: 'Informacje o obiekcie',
            select_default_size: 'Wybierz rozmiar standardowy lub wprowadź wymiary dla  produkcji specjalnej',
            default_size: 'Rozmiar standardowy (szer. x gł. W mm)',
            special_size: 'Zamówienie specjalne',
            width_in_mm: 'Szerokość w mm',
            length_in_mm: 'Głębokość w mm',
        },
        cover: {
            title: 'Zadaszenie',
        },
        color: {
            title: 'Kolor',
            title2: 'Kolor:',
        },
        wall: {
            title: 'Konstrukcja ściany',
            select: 'Proszę wybrać jeśli jest już znany model',
            beton: 'Befestigungsset Typ „Beton“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton2: 'Befestigungsset Typ „Beton 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton3: 'Befestigungsset Typ „Beton 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton2: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton3: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
        },
        your_offer: 'Twoja oferta',
        your_config: 'Konfiguracja zadaszenia tarasu',
        mm: 'mm',
        roof: 'Zadaszenie:',
        price: 'UVP:',
        static: 'Statyka / montaż',
        static_public: 'Statyka / lokalne przepisy budowlane:',
        static_public2: 'Statyka / lokalne przepisy budowlane',
        static_report: 'Raport do przedłożenia w gminie/urzędzie',
        installation: 'Montaż',
        installation_customer: 'Klienta końcowego',
        installation_customer2: 'Montaż przez klienta końcowego',
        installation_company: 'Specjalistyczną firmę',
        installation_company2: 'Montaż przez specjalistyczną firmę',
        installation_text: 'W przypadku montażu przez firmę usługową ponoszone są dodatkowe koszty, które naliczane są przez firmę montażową. Ceny montażu można znaleźć w sekcji "Pomiary i montaż elementów Premium" ',
    },
    carport: {
        name: 'Konfigurator wiaty ',
        title: 'Wiata Premium ',
        desc: 'Wiata najwyższej jakości - indywidualność, wygląd i jakość',
        config: 'Skonfiguruj wiatę Premium',
        your_offer: 'Twoja oferta',
        your_config: 'Konfiguracja zadaszenia wiata',
        mm: 'mm',
        roof: 'Zadaszenie:',
        price: 'UVP:',
        cover: {
            title: 'Zadaszenie',
        },
        color: {
            title: 'Kolor',
            title2: 'Kolor:',
        },
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
    },
    resources: {
        customers: {
            name: 'Kunde |||| Kunden',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                customerNo: 'Kunden-Nr.',
                customerNoName: 'Kunde',
                company: 'Firma',
                address: 'Adresse',
                country: 'Land',
                name: 'Name',
                name2: 'Name 2',
                name3: 'Name 3',
                zip: 'PLZ',
                city: 'Ort',
                phone: 'Telefon',
                email: 'E-Mail',
            },
            fieldGroups: {
                identity: 'Allgemein',
                address: 'Addresse',
                stats: 'Stats',
                visits: 'Besuche',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Kunden löschen',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        faq: {
            name: 'FAQ'
        },
        documentation: {
            name: 'Dokumentation'
        }
    },
    Unauthorized: 'Benutzername oder Passwort ungültig',
};

export default customPolishMessages;
