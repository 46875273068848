//import { TranslationMessages } from 'ra-core';
import czechMessages from 'ra-language-czech';

const customCzechMessages = {
    ...czechMessages,
    pos: {
        search: 'Suche',
        configuration: 'Configuration',
        language: 'Sprache',
        reset: 'Obnovit do základního nastavení',
        calculate: 'Vypočítat',
        theme: {
            name: 'Thema',
            light: 'Hell',
            dark: 'Dunkel',
        },
        dashboard: {
            welcome: {
                title: 'Configurator APP',
                subtitle:
                    "pro prémiové terasy a přístřešky",
            },
        },
        menu: {
            terrace: 'Zastřešení terasy',
            carport: 'Carport',
            help: 'Hilfe',
            home: 'Home',
        },
    },
    colors: {
        white: 'Bílá (RAL 9016)',
        weiss: 'Bílá (RAL 9016)',
        anthrazit: 'Antracit (RAL 7016)',
        ral: 'RAL ...',
    },
    covers: {
        pc_klar: 'Polycarbonat 16 mm klar',
        pc_bronce: 'Polycarbonat 16 mm bronce',
        pc_opal: 'Polycarbonat 16 mm opal',
        pc_weiss_opal: 'Polycarbonat klar weiss opal gestreift',
        acryl_klar: 'Acryl 16 mm klar',
        acryl_bronce: 'Acryl 16 mm bronce',
        acryl_klima: 'Acryl 16 mm Klima blue',
        glas: 'Echtglas 10 mm VSG',
    },
    terrace: {
        name: 'Konfigurátor terasy',
        title: 'Zastřešení terasy Premium',
        desc: 'Tam kde jsou kladeny nejvyšší nároky na kvalitu a design, tam je Terrassendach Premium tou nejlepší volbou.',
        config: 'Konfigurujte pergolu Terrassendach Premium ',
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
        objects: {
            title: 'Detaily nemovitosti',
            select_default_size: 'Vyberte standardní velikost nebo zadejte vlastní rozměry',
            default_size: 'Standardní velikost (Š x D v mm)',
            special_size: 'Zakázková výroba',
            width_in_mm: 'Šířka v mm',
            length_in_mm: 'Hloubka v mm',
        },
        cover: {
            title: 'Zastřešení',
        },
        color: {
            title: 'Barva',
            title2: 'Barva:',
        },
        wall: {
            title: 'Stěna konstrukce',
            select: 'Vyberte, pokud již víte',
            beton: 'Befestigungsset Typ „Beton“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton2: 'Befestigungsset Typ „Beton 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            beton3: 'Befestigungsset Typ „Beton 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein“\nohne Dämmung.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton2: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 2“\nmit Dämmung bis 25 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
            porenbeton3: 'Befestigungsset Typ „Porenbeton/Vollstein/Lochstein 3“\nmit Dämmung bis 32 cm.\nSet für 6 Bohrlöcher / DIBT-Zulassung',
        },
        your_offer: 'Vaše nabídka',
        your_config: 'Konfigurace terasy',
        mm: 'mm',
        roof: 'Zastřešení:',
        price: 'UVP:',
        static: 'Statika / montáž',
        static_public: 'Statické / místní stavební předpisy:',
        static_public2: 'Statické / místní stavební předpisy',
        static_report: 'Statická zpráva požadovaná k předložení obecnímu / stavebnímu úřadu',
        installation: 'Montáž',
        installation_customer: 'Koncovými zákazníky',
        installation_customer2: 'Montáž koncovými zákazníky',
        installation_company: 'Odbornou firmou',
        installation_company2: 'Montáž odbornou firmou',
        installation_text: 'Při instalaci montážní firmou vznikají dodatečné náklady, které vypočítává přímo montážní firma. Ceny za montáž naleznete v části „Zaměřovací a montážní služby pro prémiové výrobky“',
    },
    carport: {
        name: 'Konfigurátor přístřešku na auto',
        title: 'Přístřešek na auto Carport Premium',
        desc: 'Přístřešky nejvyšší kvality - s individuálním vzhledem, designem a kvalitou.',
        config: 'Konfigurujte přístřešek na auto Carport Premium',
        your_offer: 'Vaše nabídka',
        your_config: 'Konfigurace přístřešek',
        mm: 'mm',
        roof: 'Zastřešení:',
        price: 'UVP:',
        cover: {
            title: 'Zastřešení',
        },
        color: {
            title: 'Barva',
            title2: 'Barva:',
        },
        fields: {
        },
        fieldGroups: {
        },
        errors: {
        },
    },
    resources: {
        customers: {
            name: 'Kunde |||| Kunden',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                customerNo: 'Kunden-Nr.',
                customerNoName: 'Kunde',
                company: 'Firma',
                address: 'Adresse',
                country: 'Land',
                name: 'Name',
                name2: 'Name 2',
                name3: 'Name 3',
                zip: 'PLZ',
                city: 'Ort',
                phone: 'Telefon',
                email: 'E-Mail',
            },
            fieldGroups: {
                identity: 'Allgemein',
                address: 'Addresse',
                stats: 'Stats',
                visits: 'Besuche',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Kunden löschen',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        faq: {
            name: 'FAQ'
        },
        documentation: {
            name: 'Dokumentation'
        }
    },
    Unauthorized: 'Benutzername oder Passwort ungültig',
};

export default customCzechMessages;
