import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import { useTranslate } from 'react-admin';

const responsiveImage =  require('../assets/images/Carport_anthrazit.jpg?placeholder=true&sizes[]=300,sizes[]=600,sizes[]=800,sizes[]=1200,sizes[]=1600');


const useStyles = makeStyles({
  media: {
    height: '18em',
  },
  card: {
    //maxWidth: 345,
  },
  button: {
    marginLeft: "0.5rem",
  },
  whiteSpace: {
    whiteSpace: 'pre-line',
  }
});

const CarportWelcome = () => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardActionArea component={RouterLink} to="/carport/">
        <CardMedia
          component="img"
          alt="Carport Premium"
          height="300"
          image={responsiveImage.src}
          srcSet={responsiveImage.srcSet}
          title="Carport Premium"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {translate('carport.title')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.whiteSpace}>
            {translate('carport.desc')}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="medium" color="primary" component={RouterLink} to="/carport/">
          {translate('carport.config')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CarportWelcome;
