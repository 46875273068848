import React from "react";
import Typography from "@material-ui/core/Typography";
import { Card, CardActionArea, CardContent, Grid } from "@material-ui/core";
import { useTranslate } from 'react-admin';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import { StoreContainer } from "../../terraceStore";
import { IItem } from "../../types";

const coverPC16mmklar =  require('../../assets/images/2810403_2810503_2810741_PC_Hohlkammerplatte_16mm_klar.jpg?placeholder=true&sizes[]=320');
const coverPC16mmbronce =  require('../../assets/images/2810406_2810506_PC_Hohlkammerplatte_16mm_bronce.jpg?placeholder=true&sizes[]=320');
const coverPC16mmweissopal =  require('../../assets/images/2810404_2810504_PC_Hohlkammerplatte_16mm_opal_weiss.jpg?placeholder=true&sizes[]=320');
const coverPC16mmweissopal2 =  require('../../assets/images/_PC_Hohlkammerplatte_Stipes_16mm_klar.jpg?placeholder=true&sizes[]=320');
const coverAcryl16mmklar =  require('../../assets/images/2630203_2630253_Acryl_Stegplatten_16_32_klar.jpg?placeholder=true&sizes[]=320');
const coverAcryl16mmbronce =  require('../../assets/images/2630224_2630278_Acryl_Stegplatten_16_32_bronce.jpg?placeholder=true&sizes[]=320');
const coverAcryl16mmKlimablue =  require('../../assets/images/2630803_2630877_Acryl_Stegplatten_16_32_klima_blue.jpg?placeholder=true&sizes[]=320');
const coverEchtglas10mmVSG =  require('../../assets/images/Echtglas.jpg?placeholder=true&sizes[]=320');

const imagesMap: Record<string, any> = {
  'pc_klar' : coverPC16mmklar,
  'pc_bronce' : coverPC16mmbronce,
  'pc_opal' : coverPC16mmweissopal,
  'pc_weiss_opal' : coverPC16mmweissopal2,
  'acryl_klar' : coverAcryl16mmklar,
  'acryl_bronce' : coverAcryl16mmbronce,
  'acryl_klima' : coverAcryl16mmKlimablue,
  'glas' : coverEchtglas10mmVSG,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    radio: {
      '&&': {
        position: 'absolute',
        opacity: 0,
        width: 0,
        height: 0,
      },
      '&& + img': {
        cursor: 'pointer',
      },
      '&&:checked + img': {
        outline: '2px solid #37474f',
      },
      '&& + img + div': {
        cursor: 'pointer',
      },
      '&&:checked + img + div': {
        backgroundColor: '#37474f',
        color: '#fff',
      },
    },
    media: {
      height: 100,
    },
  }),
);

const TeraceCoverItems = () => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const isDataAvailable = (store.data && store.data.covers && store.data.covers.length > 0);
  const translate = useTranslate();

  return (
    isDataAvailable &&
    store.data.covers.map((c: IItem) => (
      <Grid item sm={4} xs={6} key={c.value}>
        <Card>
          <CardActionArea onClick={() => store.handleCoverValueChange(c.value)}>
            <label>
              <input type="radio"
                     className={classes.radio}
                     key={c.value}
                     name="cover"
                     value={c.value}
                     checked={(store.cover as string) === c.value} />
              <CardMedia
                component="img"
                alt={translate(`covers.${c.value}`)}
                className={classes.media}
                image={imagesMap[c.value].src}
                srcSet={imagesMap[c.value].srcSet}
                title={translate(`covers.${c.value}`)}
              />
              <CardContent>
                {translate(`covers.${c.value}`)}
              </CardContent>
            </label>
          </CardActionArea>
        </Card>
      </Grid>
    ))
  );
};

const TerraceCover = () => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();
  const isDataAvailable = (store.data && store.data.covers && store.data.covers.length > 0);
  const translate = useTranslate();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="inherit" component="h2">
          {translate('terrace.cover.title')}
        </Typography>
      </Grid>
      {
        isDataAvailable ? (
          <TeraceCoverItems />
        ) : (<div/>)
      }
    </Grid>
  )
};

export default TerraceCover;
