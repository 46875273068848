export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        primary: {
            light: '#5f5fc4',
            main: '#91c53c',
            dark: '#001064',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#27853b',
            dark: '#001064',
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            light: '#5f5fc4',
            main: '#27853b',
            dark: '#69b022',
            contrastText: '#fff',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#91c53c',
            dark: '#69b022',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
};
